import React from "react";
import MainInbox from "../pages/adminpages/inbox/MainInbox";

const AdminboxRoute = () => {
  return (
    <div>
      <MainInbox />
    </div>
  );
};

export default AdminboxRoute;
